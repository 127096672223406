<template>
  <div ref="el" class="relative overflow-y-auto">
    <div
      :class="
        !top && !targetIsVisible ? 'opacity-100 border-black/10' : 'opacity-0'
      "
      class="pointer-events-none sticky left-0 top-0 z-10 -mb-3 h-3 w-full border-t bg-gradient-to-b from-black/10 to-transparent transition-opacity"
    />

    <div ref="wrapper">
      <slot />
    </div>

    <div
      :class="
        (!bottom || (initialBottom && !isScrolled)) && !targetIsVisible
          ? 'opacity-100 border-black/10'
          : 'opacity-0'
      "
      class="pointer-events-none sticky bottom-0 left-0 z-10 -mt-3 h-3 w-full border-b bg-gradient-to-t from-black/10 to-transparent transition-opacity"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import { useIntersectionObserver, useScroll } from '@vueuse/core';

const el = ref<HTMLElement | null>(null);

const wrapper = ref<HTMLElement | null>(null);

const targetIsVisible = ref(false);

const { arrivedState, isScrolling } = useScroll(el, {
  offset: { top: 2, bottom: 2 },
});

const initialBottom = ref(false);

const isScrolled = ref(false);

const { top, bottom } = toRefs(arrivedState);

useIntersectionObserver(
  wrapper,
  ([{ isIntersecting }]) => {
    targetIsVisible.value = isIntersecting;

    setTimeout(() => {
      requestAnimationFrame(() => {
        if (!el.value) return;

        const { height } = el.value.getBoundingClientRect();
        const { height: wrapperHeight } =
          wrapper.value!.getBoundingClientRect();

        initialBottom.value = wrapperHeight > height;
      });
    }, 300);
  },
  { root: el, threshold: 1 },
);

watch(isScrolling, (value) => {
  if (value) {
    isScrolled.value = true;
  }
});
</script>
